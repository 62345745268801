<div class="side_bar_common_css">
  <app-sidenav-bar></app-sidenav-bar>
  <div class="dashBoard_main_div_container">
    <div class="notificationBarDash">
    <div style="width: 100%;">
      <app-blog-scroll></app-blog-scroll>
    </div>
      <img
        *ngIf="role == 1"
        src="../../../assets/img/setting.png"
        (click)="navigate('/settings')"
        style="width: 35px; border-radius: 25px; cursor: pointer"
        alt=""
        srcset=""
        class="side_nav_bottom_dp"
      />
      <img
        *ngIf="!isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/NotifiationBell.svg"
      />
      <img
        *ngIf="isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/notificationIcon.svg"
      />
      <div class="shwoNotififications" *ngIf="showNotif">
        <div class="d-flex justify-content-end">
          <img
            style="width: 20px"
            (click)="toggleNotif()"
            src="../../../assets/img/carbon_close-filled.svg"
          />
        </div>
        <div *ngIf="notifications.length == 0">
          <div class="notificationHeader">No Notifications Found</div>
        </div>
        <div
          *ngFor="let notif of notifications"
          class="notif"
          (click)="readNotifications(notif)"
        >
          <div class="notificationHeader">
            <div>
              {{ notif.type.toUpperCase().split("_").join(" ") }}
            </div>
            <div>
              {{ notif.created_at }}
            </div>
          </div>
          <div class="notificationContent">
            {{ notif.remarks }}
          </div>
        </div>
      </div>
      <button mat-button [matMenuTriggerFor]="menu" style="padding: 0">
        <img
          [src]="photo"
          style="width: 35px; border-radius: 25px"
          alt=""
          srcset=""
          class="side_nav_bottom_dp"
        />
      </button>
      <mat-menu #menu="matMenu">
        <div
          style="
            color: black;
            padding: 10px;
            font-weight: 700;
            border-bottom: 1px solid #a3c17e;
            width: 100%;
            max-width: 10rem;
            margin-right: 1rem;
          "
        >
          {{ username.toUpperCase() }}
        </div>
        <button mat-menu-item (click)="navigate('/profile')">
          <img style="width: 25px; margin-right: 10px" [src]="tPhoto" alt="" />
          Profile
        </button>
        <button mat-menu-item (click)="navigate('/login')">
          <img
            style="width: 25px; margin-right: 10px"
            src="../../../assets/img/logout.svg"
            alt=""
          />
          Logout
        </button>
      </mat-menu>
    </div>
    <div class="dash_main_header">DASHBOARD</div>
    <div style="width: 100%; height: 100%; padding: 1.3rem">
      <app-u0-dashboard
        *ngIf="role == 1"
        (messageEmitter)="changeTab($event)"
      ></app-u0-dashboard>
      <app-u3-dashboard
        *ngIf="role == 5"
        (messageEmitter)="changeTab($event)"
      ></app-u3-dashboard>
      <app-u2-dashboard *ngIf="role == 3" (messageEmitter)="changeTab($event)">
      </app-u2-dashboard>
      <app-u1-dashboard
        *ngIf="role == 2"
        (messageEmitter)="changeTab($event)"
      ></app-u1-dashboard>
    </div>
  </div>
</div>
