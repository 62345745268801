<div class="ces_main_div" *ngIf="roleId!=3">
  <div class="ces_header">Add Client Engagement Specialist Details</div>
  <div class="ces_content_main_div">
    <div class="ces_sub_header">
      <div [ngClass]="this.stepperFlag['personal_dtl']?'active_stepper':''">
        PERSONAL DETAILS
      </div>
      <div [ngClass]="this.stepperFlag['assign_bm']?'active_stepper':''">
        ASSIGN BUSINESS MANAGERS
      </div>
      <div [ngClass]="this.stepperFlag['assign_reatil']?'active_stepper':''">
        ASSIGN REATIL USER
      </div>
    </div>
    <div class="ces_inp_main_div">
      <!-- ------------------------------------------ PERSONAL DETAILS --------------------------------------------------- -->

      <div class="ces_pd_main_div" *ngIf="this.stepperFlag['personal_dtl']">
        <div class="ces_pd_inp_main_div">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Client Engagement Specialist Name</label>
            <input type="text" [(ngModel)]="cesName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" [(ngModel)]="cesEmail" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" [(ngModel)]="cesNumber" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="password">Create Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="conpasswordToggleHide?'password':'text'"
                name="amConfirmPassword"
                id=""
                [(ngModel)]="cesNewPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="conpasswordToggleHide"
                (click)="conpasswordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!conpasswordToggleHide"
                (click)="conpasswordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="confirm_password">Confirm Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="passwordToggleHide?'password':'text'"
                name="cesConfirmPassword"
                id=""
                [(ngModel)]="cesConfirmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="passwordToggleHide"
                (click)="passwordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!passwordToggleHide"
                (click)="passwordToggleHide = true"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------ ASSIGN BM --------------------------------------------------- -->

      <div *ngIf="this.stepperFlag['assign_bm']">
        <div class="ces_assign_bm_main_div">
          <div class="ces_assign_bm_search_main_div">
            <div class="ces_assign_bm_search_headder">
              Assign Business Managers
            </div>
            <div class="ces_assign_bm_search_inp_main_div">
              <div class="ces_assign_bm_search_inp_div">
                <input
                  type="text"
                  placeholder="Choose Business Manager"
                  matInput
                  [(ngModel)]="autocompleteControl"
                  [matAutocomplete]="auto1"
                  (input)="filter()"
                  [disabled]="readonlyToggleFlag"
                  class="checkbox_multiselect"
                />
                <img src="../../../assets/img/drowpdown_icon.svg" alt="" />
              </div>
              <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions">
                  <div
                    (click)="toggleSelection(option)"
                    class="checkbox_multiselect_main_div"
                  >
                    <input
                      type="checkbox"
                      [checked]="isSelected(option)"
                      class="form-check-input checkbox_multiselect"
                    />
                    <span class="checkbox_multiselect_txt"
                      >{{ option.name }}</span
                    >
                  </div>
                </mat-option>
              </mat-autocomplete>
            </div>

            <div class="ces_assign_bm_box_main_div">
              <div class="ces_assign_bm_box_sub_div">
                <div
                  *ngIf="selectedOptions.length > 0"
                  style="display: flex; flex-direction: column; height: 100%"
                >
                  <div class="ces_assign_bm_box_header_div">
                    <div>SL.</div>
                    <div>Business Managers</div>
                    <div>Company Name</div>
                    <div>Remove</div>
                  </div>
                  <div class="ces_assign_bm_box_main_div">
                    <div
                      *ngFor="let item of selectedOptions;let i = index"
                      class="ces_assign_bm_box_content_div"
                    >
                      <div>{{i+1}}</div>
                      <div>{{item.name}}</div>
                      <div>{{item.company_name}}</div>
                      <div>
                        <img
                          src="../../../assets/img/remove_icon.svg"
                          alt=""
                          style="cursor: pointer"
                          (click)="toggleSelection(item)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- --------------------------------------- ASSIGN RETAIL --------------------------------------------------- -->

      <div *ngIf="this.stepperFlag['assign_reatil']">
        <div class="ces_assign_bm_main_div">
          <div class="ces_assign_bm_search_main_div">
            <div class="ces_assign_bm_search_headder">Assign Retail User</div>
            <div class="ces_assign_bm_search_inp_main_div">
              <div class="ces_assign_bm_search_inp_div">
                <input
                  type="text"
                  placeholder="Choose Retail User"
                  [(ngModel)]="autocompleteSearch"
                  [matAutocomplete]="auto"
                  (input)="filterRetailUser()"
                  [disabled]="readonlyRetailToggleFlag"
                  class="checkbox_multiselect"
                />
                <img src="../../../assets/img/drowpdown_icon.svg" alt="" />
              </div>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredReatailUser">
                  <div
                    (click)="toggleRetailSelection(option)"
                    class="checkbox_multiselect_main_div"
                  >
                    <input
                      type="checkbox"
                      [checked]="isRetailSelected(option)"
                      class="form-check-input checkbox_multiselect"
                    />
                    <span class="checkbox_multiselect_txt">{{ option }}</span>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </div>

            <div class="ces_assign_bm_box_main_div">
              <div class="ces_assign_bm_box_sub_div">
                <div
                  *ngIf="selectedRetailUser.length > 0"
                  style="display: flex; flex-direction: column; height: 100%"
                >
                  <div class="ces_assign_bm_box_header_div">
                    <div>SL.</div>
                    <div>Retail User</div>
                    <div>Company Name</div>
                    <div>Remove</div>
                  </div>
                  <div class="ces_assign_bm_box_main_div">
                    <div
                      *ngFor="let item of selectedRetailUser;let i = index"
                      class="ces_assign_bm_box_content_div"
                    >
                      <div>{{i+1}}</div>
                      <div>{{item}}</div>
                      <div>iglulabs</div>
                      <div>
                        <img
                          src="../../../assets/img/remove_icon.svg"
                          alt=""
                          style="cursor: pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="flex: 1">&nbsp;</div>
  <div class="ces_btn_main_div">
    <div class="" *ngIf="roleId"></div>
    <div class="ces_pd_btn_div">
      <div (click)="close()">close</div>
      <div (click)="addNewCesFunc()">ADD</div>
    </div>
  </div>
</div>

<!-- ------------------------    BUSSINESS MANAGER ---------------------------------------- -->
<!-- ------------------------    BUSSINESS MANAGER ---------------------------------------- -->
<!-- ------------------------    BUSSINESS MANAGER ---------------------------------------- -->

<div class="ces_main_div" *ngIf="roleId==3">
  <div
    class="ces_header"
    style="flex-direction: column; align-items: flex-start"
  >
    <div class="bm_header">
      <div *ngIf="!isModify">Add User</div>
      <div *ngIf="isModify && data.from=='enterprise' ">
        MODIFY ENTERPRISE USER
      </div>
      <div *ngIf="isModify && data.from=='retail' ">MODIFY RETAIL USER</div>
      <div class="ces_icons_header">
        <img
          src="../../../assets/img/reset_icon.svg"
          alt=""
          srcset=""
          style="cursor: pointer"
          (click)="handleBmReset()"
        />
        <img
          src="../../../assets/img/remove_icon.svg"
          alt=""
          style="cursor: pointer"
          (click)="handleBmDelete()"
        />
        <img
          src="../../../assets/img/close.svg"
          alt=""
          style="cursor: pointer"
          (click)="close()"
        />
      </div>
    </div>
    <!-- <div class="ces_content_main_div bm_content_div"  *ngIf="!isModify">
        <div class="bm_sub_header">
            <div style="margin-right: 2rem;"> <input type="radio" name="typeOfUser" id="" checked (click)="type = 'beu'"> <span>Enterprise
                    User</span></div>
            <div><input type="radio" name="typeOfUser" id="" (click)="type = 'retail'"> <span>Retail User</span></div>
           
        </div>
    </div> -->
  </div>
  <div class="ces_content_main_div">
    <!-- <div class="ces_sub_header">
            <div  [ngClass]="this.stepperFlag['personal_dtl']?'active_stepper':''">
                PERSONAL DETAILS</div>
            <div  [ngClass]="this.stepperFlag['assign_bm']?'active_stepper':''">
                ASSIGN BUSINESS MANAGERS</div>
            <div 
                [ngClass]="this.stepperFlag['assign_reatil']?'active_stepper':''">
                ASSIGN REATIL USER
            </div>
        </div> -->
    <div class="ces_inp_main_div">
      <!-- ------------------------------------------ ENTERPRISE USER --------------------------------------------------- -->

      <div class="ces_pd_main_div" *ngIf="type=='beu'">
        <div class="ces_pd_inp_main_div_BM">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Name</label>
            <input type="text" [(ngModel)]="bmName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" [(ngModel)]="bmEmail" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" [(ngModel)]="bmNumber" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="company_name">Company Name</label>
            <input type="text" [(ngModel)]="bmComName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="city">Address</label>
            <input type="text" [(ngModel)]="bmAddress" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="city">City</label>
            <input type="text" [(ngModel)]="bmCity" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="state">State</label>
            <!-- <input type="text" [(ngModel)]="bmState"> -->
            <ng-select
              class="multi-select"
              [items]="allState"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              [(ngModel)]="bmState"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="!isModify">
            <label for="password">Create Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="conpasswordToggleHide?'password':'text'"
                name="bmNewPassword"
                id=""
                [(ngModel)]="bmNewPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="conpasswordToggleHide"
                (click)="conpasswordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!conpasswordToggleHide"
                (click)="conpasswordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="!isModify">
            <label for="confirm_password">Confirm Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="passwordToggleHide?'password':'text'"
                name="bmNewPassword"
                id=""
                [(ngModel)]="bmConfirmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="passwordToggleHide"
                (click)="passwordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!passwordToggleHide"
                (click)="passwordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="isModify">
            <div class="license_modify_pd_inp_sub_div">
              <label for="pin_code">Status</label>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle updateSelect input_disable"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="width: 100%; text-align: left; min-height: 48px"
                >
                  <span *ngIf="bmtxtStatus == ''">&nbsp;</span>
                  <span
                    *ngIf="bmtxtStatus != ''"
                    [ngClass]="bmtxtStatus == 'active'?'colorGreen':bmtxtStatus == 'inactive'?'colorRed':'colorBlue'"
                  >
                    {{bmtxtStatus}}</span
                  >
                </button>
                <ul class="dropdown-menu" style="width: 100%">
                  <li
                    class="dropdown-item"
                    style="color: #12c058"
                    (click)="handleStatusChange('Active')"
                  >
                    Active
                  </li>
                  <li
                    class="dropdown-item"
                    style="color: #d43738"
                    (click)="handleStatusChange('Inactive')"
                  >
                    Inactive
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="ces_pd_inp_sub_div"
            *ngIf="role != '3' && isModify"
            style="
              color: #1968b1;
              text-decoration: underline;
              margin: auto 0;
              cursor: pointer;
            "
            (click)="handleViewLicense()"
          >
            View Licenses
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="role == '3' && isModify">
            <label for="status">Licenses List</label>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle updateSelect"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="width: 100%; text-align: left; min-height: 48px"
              >
                <span style="font-size: 14px; color: grey">View Licenses</span>
              </button>
              <ul
                class="dropdown-menu"
                style="width: 100%"
                *ngIf="!listOfLicense"
              >
                <span class="dropdown-item">No Licenses Found</span>
              </ul>
              <ul
                class="dropdown-menu"
                style="width: 100%"
                *ngIf="listOfLicense?listOfLicense.length>0:''"
              >
                <li
                  *ngFor="let data of listOfLicense"
                  class="dropdown-item"
                  (click)="handleOpenLicensePopup(data.name,data.id)"
                >
                  {{data.name}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="ces_pd_main_div" *ngIf="type=='retail'">
        <div class="ces_pd_inp_main_div_BM">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Name</label>
            <input type="text" [(ngModel)]="bmRetailName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" [(ngModel)]="bmRetailEmail" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" [(ngModel)]="bmRetailNumber" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="company_name">Company Name</label>
            <input type="text" [(ngModel)]="bmRetailComName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="city">City</label>
            <input type="text" [(ngModel)]="bmRetailCity" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="state">State</label>
            <!-- <input type="text" [(ngModel)]="bmRetailState"> -->
            <ng-select
              class="multi-select"
              [items]="allState"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              [(ngModel)]="bmRetailState"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="!isModify">
            <label for="password">Create Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="conpasswordToggleHide?'password':'text'"
                name="bmRetailNewPassword"
                id=""
                [(ngModel)]="bmRetailNewPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="conpasswordToggleHide"
                (click)="conpasswordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!conpasswordToggleHide"
                (click)="conpasswordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="!isModify">
            <label for="confirm_password">Confirm Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="passwordToggleHide?'password':'text'"
                name="bmRetailNewPassword"
                id=""
                [(ngModel)]="bmRetailConfirmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="passwordToggleHide"
                (click)="passwordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!passwordToggleHide"
                (click)="passwordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="isModify">
            <div class="license_modify_pd_inp_sub_div">
              <label for="pin_code">Status</label>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle updateSelect input_disable"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="width: 100%; text-align: left; min-height: 48px"
                >
                  <span *ngIf="bmRetailtxtStatus == ''">&nbsp;</span>
                  <span
                    *ngIf="bmRetailtxtStatus != ''"
                    [ngClass]="bmRetailtxtStatus == 'active'?'colorGreen':bmRetailtxtStatus == 'inactive'?'colorRed':'colorBlue'"
                  >
                    {{bmRetailtxtStatus}}</span
                  >
                </button>
                <ul class="dropdown-menu" style="width: 100%">
                  <li
                    class="dropdown-item"
                    style="color: #12c058"
                    (click)="handleStatusChange('Active')"
                  >
                    Active
                  </li>
                  <li
                    class="dropdown-item"
                    style="color: #d43738"
                    (click)="handleStatusChange('Inactive')"
                  >
                    In Active
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="ces_pd_inp_sub_div"
            *ngIf="role != '3' && isModify"
            style="
              color: #1968b1;
              text-decoration: underline;
              margin: auto 0;
              cursor: pointer;
            "
            (click)="handleViewLicense()"
          >
            View Licenses
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="role == '3' && isModify">
            <label for="status">Licenses List</label>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle updateSelect"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="width: 100%; text-align: left; min-height: 48px"
              >
                <span style="font-size: 14px; color: grey">View Licenses</span>
              </button>
              <ul
                class="dropdown-menu"
                style="width: 100%"
                *ngIf="!listOfLicense"
              >
                <span class="dropdown-item">No Licenses Found</span>
              </ul>
              <ul
                class="dropdown-menu"
                style="width: 100%"
                *ngIf="listOfLicense?listOfLicense.length>0:''"
              >
                <li *ngFor="let data of listOfLicense" class="dropdown-item">
                  {{data.name}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------ ASSIGN BM --------------------------------------------------- -->

      <!-- <div *ngIf="this.stepperFlag['assign_bm']">
                <div class="ces_assign_bm_main_div">
                    <div class="ces_assign_bm_search_main_div">
                        <div class="ces_assign_bm_search_headder">Assign Business Managers</div>
                        <div class="ces_assign_bm_search_inp_main_div">
                            <div class="ces_assign_bm_search_inp_div">
                                <input type="text" placeholder="Choose Business Manager" matInput
                                    [(ngModel)]="autocompleteControl" [matAutocomplete]="auto1" (input)="filter()"
                                    [disabled]="readonlyToggleFlag" class="checkbox_multiselect">
                                <img src="../../../assets/img/drowpdown_icon.svg" alt="">
                            </div>
                            <mat-autocomplete #auto1="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions">
                                    <div (click)="toggleSelection(option)" class="checkbox_multiselect_main_div">
                                        <input type="checkbox" [checked]="isSelected(option)"
                                            class="form-check-input checkbox_multiselect">
                                        <span class="checkbox_multiselect_txt">{{ option.name }}</span>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <div class="ces_assign_bm_box_main_div">
                            <div class="ces_assign_bm_box_sub_div">
                                <div *ngIf="selectedOptions.length > 0"
                                    style="display: flex;flex-direction: column;height: 100%;">
                                    <div class="ces_assign_bm_box_header_div">
                                        <div>SL.</div>
                                        <div>Business Managers</div>
                                        <div>Company Name</div>
                                        <div>Remove</div>
                                    </div>
                                    <div class="ces_assign_bm_box_main_div">
                                        <div *ngFor="let item of selectedOptions;let i = index"
                                            class="ces_assign_bm_box_content_div">
                                            <div>{{i+1}}</div>
                                            <div>{{item.name}}</div>
                                            <div>{{item.company_name}}</div>
                                            <div><img src="../../../assets/img/remove_icon.svg" alt=""
                                                    style="cursor: pointer;" (click)="toggleSelection(item)"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

      <!-- --------------------------------------- ASSIGN RETAIL --------------------------------------------------- -->

      <!-- <div *ngIf="this.stepperFlag['assign_reatil']">
                <div class="ces_assign_bm_main_div">
                    <div class="ces_assign_bm_search_main_div">
                        <div class="ces_assign_bm_search_headder">Assign Retail User</div>
                        <div class="ces_assign_bm_search_inp_main_div">
                            <div class="ces_assign_bm_search_inp_div">
                                <input type="text" placeholder="Choose Retail User" 
                                    [(ngModel)]="autocompleteSearch" [matAutocomplete]="auto"
                                    (input)="filterRetailUser()" [disabled]="readonlyRetailToggleFlag"
                                    class="checkbox_multiselect">
                                <img src="../../../assets/img/drowpdown_icon.svg" alt="">
                            </div>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredReatailUser">
                                    <div (click)="toggleRetailSelection(option)" class="checkbox_multiselect_main_div">
                                        <input type="checkbox" [checked]="isRetailSelected(option)"
                                            class="form-check-input checkbox_multiselect">
                                        <span class="checkbox_multiselect_txt">{{ option }}</span>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                        <div class="ces_assign_bm_box_main_div">
                            <div class="ces_assign_bm_box_sub_div">
                                <div *ngIf="selectedRetailUser.length > 0"
                                    style="display: flex;flex-direction: column;height: 100%;">
                                    <div class="ces_assign_bm_box_header_div">
                                        <div>SL.</div>
                                        <div>Retail User</div>
                                        <div>Company Name</div>
                                        <div>Remove</div>
                                    </div>
                                    <div class="ces_assign_bm_box_main_div">
                                        <div *ngFor="let item of selectedRetailUser;let i = index"
                                            class="ces_assign_bm_box_content_div">
                                            <div>{{i+1}}</div>
                                            <div>{{item}}</div>
                                            <div>iglulabs</div>
                                            <div><img src="../../../assets/img/remove_icon.svg" alt=""
                                                    style="cursor: pointer;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
  <div style="flex: 1">&nbsp;</div>
  <div class="ces_btn_main_div">
    <div
      class="ces_pd_btn_div_new"
      (click)="handleAddNewLicense()"
      *ngIf="isModify"
    >
      <div>Add New License</div>
    </div>
    <div *ngIf="!isModify"></div>
    <div class="ces_pd_btn_div">
      <div (click)="close()">Cancel</div>
      <div (click)="addNewBMFunc()" *ngIf="!isModify">ADD</div>
      <div (click)="modifyBMUser()" *ngIf="isModify">UPDATE</div>
    </div>
  </div>
</div>
