<div class="license_main_div">
  <div class="header">LICENSES</div>
  <div class="swiching_web_user_btn_main_div">
    <button
      class="swiching_web_user_btn"
      [ngClass]="enterpriseActiveFlag?'swiching_web_user_btn_active':''"
      (click)="handleSwicthWebUser('beu')"
    >
      ENTERPRISE USER
    </button>
    <!-- <button class="swiching_web_user_btn" [ngClass]="enterpriseActiveFlag?'':'swiching_web_user_btn_active'"
            (click)="handleSwicthWebUser('retail')">RETAIL USER</button> -->
  </div>
  <div class="license_content_main_div" *ngIf="enterpriseActiveFlag">
    <div>
      <div class="license_search_main_div">
        <div class="license_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilter($event)"
              autocomplete="off"
            />
          </div>
          <div class="dropdown">
            <button
              class="btn filter_btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="../../../assets/img/../img/filter_icon.svg"
                alt=""
              />Filters
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.all?'filterActiveCss':''"
                (click)="filterByRetailStatus('All')"
              >
                All
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.active?'filterActiveCss':''"
                (click)="filterByRetailStatus('Active')"
              >
                Active
              </li>
              <li
                class="dropdown-item"
                style="color: #d43738"
                [ngClass]="selectedFilter.expired?'filterActiveCss':''"
                (click)="filterByRetailStatus('Expired')"
              >
                Expired
              </li>
              <li
                class="dropdown-item"
                style="color: #1968b1"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('Requested')"
              >
                Requested
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('30')"
              >
                Expiring in less than 30 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('60')"
              >
                Expiring in 60 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('90')"
              >
                Expiring in 90 days
              </li>
            </ul>
          </div>
          <div
            class="filterApplied"
            (click)="filterByRetailStatus('All')"
            *ngIf="selectedFilterDropdown!='All'"
          >
            {{selectedFilterDropdown}}
            <img
              style="width: 15px; height: 15px; margin-left: 20px"
              src="../../../assets/img/xmark.svg"
            />
          </div>
        </div>
      </div>

      <div class="license_table_main_div">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{i+1}}
            </td>
          </ng-container>

          <!-- License Number  -->

          <ng-container matColumnDef="registration_no">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>License Number</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUNameFilter?EUNameFilter:'All'}}
                    <img
                      (click)="EUApplyFilter('','name',1)"
                      style="width: 20px"
                      *ngIf="EUNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUNameDropdown"
                      (click)="EUApplyFilter(status,'name',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="color: #7893ec; cursor: pointer"
              (click)="modifyAppUser(element.registration_no,'enterprise',element)"
            >
              {{element.registration_no}}
            </td>
          </ng-container>

          <!-- Company Name  -->
          <ng-container matColumnDef="user_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>User Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUUserNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUUserNameFilter?EUUserNameFilter:'All'}}
                    <img
                      (click)="EUApplyFilter('','uname',1)"
                      style="width: 20px"
                      *ngIf="EUUserNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUUserNameDropdown"
                      (click)="EUApplyFilter(status,'uname',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              (click)="getUserDetails(element.user_id,'eu')"
              style="color: #7893ec; cursor: pointer"
              mat-cell
              *matCellDef="let element"
            >
              {{element.user_name.user_name}}
            </td>
          </ng-container>

          <ng-container matColumnDef="business_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUCompanyFilter?EUCompanyFilter:'All'}}
                    <img
                      (click)="EUApplyFilter('','company',1)"
                      style="width: 20px"
                      *ngIf="EUCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUCompanyDropdown"
                      (click)="EUApplyFilter(status,'company',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.business_name}}
            </td>
          </ng-container>

          <!-- City -->

          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUCityFilter?EUCityFilter:'All'}}
                    <img
                      (click)="EUApplyFilter('','city',1)"
                      style="width: 20px"
                      *ngIf="EUCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUCityDropdown"
                      (click)="EUApplyFilter(status,'city',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.state}}
            </td>
          </ng-container>

          <!-- Status -->

          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUStatusFilter?EUStatusFilter:'All'}}
                    <img
                      (click)="EUApplyFilter('','status',1)"
                      style="width: 20px"
                      *ngIf="EUStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUStatusDropdown"
                      (click)="EUApplyFilter(status,'status',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Expired'?'colorRed':'colorBlue'"
              >
                {{element.status}}</span
              >
            </td>
          </ng-container>

          <!--  View License -->

          <ng-container matColumnDef="view_license">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              View License
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
              [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
              alt="View License"
              [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
              (click)="element.documents ? viewLicence(element.documents) : null"
            />
            </td>
          </ng-container>

          <!-- Modify -->

          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/edit_icon.svg"
                alt=""
                style="cursor: pointer"
                (click)="modifyAppUser(element.registration_no,'enterprise',element)"
              />
            </td>
          </ng-container>
          <ng-container matColumnDef="serviceReq">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Service Request
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element; let i = index"
            >
              <img
                (click)="addRequest(element)"
                style="width: 40px"
                src="../../../assets//img//serviceReq.svg"
              />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="license_content_main_div" *ngIf="!enterpriseActiveFlag">
    <div>
      <div class="license_search_main_div">
        <div class="license_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyRetailFilter($event)"
              autocomplete="off"
            />
          </div>
          <div class="dropdown">
            <button
              class="btn filter_btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="../../../assets/img/../img/filter_icon.svg"
                alt=""
              />Filters
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedRetailFilter.all?'filterActiveCss':''"
                (click)="filterByRetailStatus('All')"
              >
                All
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedRetailFilter.active?'filterActiveCss':''"
                (click)="filterByRetailStatus('Active')"
              >
                Active
              </li>
              <li
                class="dropdown-item"
                style="color: #d43738"
                [ngClass]="selectedRetailFilter.expired?'filterActiveCss':''"
                (click)="filterByRetailStatus('Expired')"
              >
                Expired
              </li>
              <li
                class="dropdown-item"
                style="color: #1968b1"
                [ngClass]="selectedRetailFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('Requested')"
              >
                Requested
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('30')"
              >
                Expiring in less than 30 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('60')"
              >
                Expiring in 60 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('90')"
              >
                Expiring in 90 days
              </li>
            </ul>
          </div>
          <div
            class="filterApplied"
            (click)="filterByRetailStatus('All')"
            *ngIf="selectedFilterDropdown!='All'"
          >
            {{selectedFilterDropdown}}
            <img
              style="width: 15px; height: 15px; margin-left: 20px"
              src="../../../assets/img/xmark.svg"
            />
          </div>
        </div>
      </div>
      <div class="license_table_main_div">
        <table
          mat-table
          [dataSource]="dataRetailSource"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{i+1}}
            </td>
          </ng-container>

          <!-- License Number  -->

          <ng-container matColumnDef="registration_no">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>License Number</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RUNameFilter?RUNameFilter:'All'}}
                    <img
                      (click)="RUApplyFilter('','name',1)"
                      style="width: 20px"
                      *ngIf="RUNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RUNameDropdown"
                      (click)="RUApplyFilter(status,'name',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.registration_no}}
            </td>
          </ng-container>

          <!-- Company Name  -->

          <ng-container matColumnDef="business_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RUCompanyFilter?RUCompanyFilter:'All'}}
                    <img
                      (click)="RUApplyFilter('','company',1)"
                      style="width: 20px"
                      *ngIf="RUCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RUCompanyDropdown"
                      (click)="RUApplyFilter(status,'company',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.business_name}}
            </td>
          </ng-container>

          <!-- City Name  -->

          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RUCityFilter?RUCityFilter:'All'}}
                    <img
                      (click)="RUApplyFilter('','city',1)"
                      style="width: 20px"
                      *ngIf="RUCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RUCityDropdown"
                      (click)="RUApplyFilter(status,'city',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.state}}
            </td>
          </ng-container>

          <!-- Status -->

          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RUStatusFilter?RUStatusFilter:'All'}}
                    <img
                      (click)="RUApplyFilter('','status',1)"
                      style="width: 20px"
                      *ngIf="RUStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RUStatusDropdown"
                      (click)="RUApplyFilter(status,'status',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Expired'?'colorRed':'colorBlue'"
              >
                {{element.status}}</span
              >
            </td>
          </ng-container>

          <!--  View License -->

          <ng-container matColumnDef="view_license">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              View License
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
              [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
              alt="View License"
              [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
              (click)="element.documents ? viewLicence(element.documents) : null"
            />
            </td>
          </ng-container>

          <!-- Modify -->

          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/edit_icon.svg"
                alt=""
                style="cursor: pointer"
                (click)="modifyAppUser(element.registration_no,'retail',element)"
              />
            </td>
          </ng-container>
          <ng-container matColumnDef="serviceReq">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Service Request
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element; let i = index"
            >
              <img
                (click)="addRequest(element)"
                style="width: 40px"
                src="../../../assets//img//serviceReq.svg"
              />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedRetailColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedRetailColumns;"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</div>
