<div class="ces_main_div">
  <div class="ces_header">Add User</div>
  <div class="ces_content_main_div">
    <div class="ces_sub_header add_user_header">
      <div>
        <input
          type="radio"
          name="typeOfUser"
          id=""
          checked
          (click)="type = 'beu'"
        />
        <span>Enterprise User</span>
      </div>
      <div>
        <input type="radio" name="typeOfUser" id="" (click)="type = 'retail'" />
        <span>Retail User</span>
      </div>
      <!-- <div><input type="radio" name="typeOfUser" id="" (click)="type = 'BM'"> <span>Business Manager</span></div>
            <div *ngIf="roleId !=2"><input type="radio" name="typeOfUser" id="" (click)="type = 'AM'"> <span>Account Manager</span></div> -->
    </div>
    <div class="ces_inp_main_div">
      <!-- ------------------------------------------ BEU DETAILS --------------------------------------------------- -->

      <div class="ces_pd_main_div" *ngIf="type == 'beu'">
        <div class="ces_pd_inp_main_div">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Name</label>
            <input type="text" name="beuName" [(ngModel)]="beuName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" name="beuEmail" [(ngModel)]="beuEmail" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" name="beuNumber" [(ngModel)]="beuNumber" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">Company Name</label>
            <input type="text" name="beuCompName" [(ngModel)]="beuCompName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">Address</label>
            <input type="text" name="beuCity" [(ngModel)]="beuAddress" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">City</label>
            <input type="text" name="beuCity" [(ngModel)]="beuCity" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="state">State</label>
            <ng-select
              class="multi-select"
              [items]="allState"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              (change)="onMaterialGroupChange($event)"
              [(ngModel)]="beuSelectedState"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="roleId == '2'">
            <label for="state">Business Manager</label>
            <ng-select
              class="multi-select"
              [items]="accManagerBMList"
              bindLabel="name"
              bindValue="name"
              [compareWith]="compareFn"
              [multiple]="false"
              placeholder="Select Business Manager"
              (change)="onBusinessManagerChange($event)"
              [(ngModel)]="beuSelectedBusinessManager"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="password">Create Password</label>
            <div class="passwordToggler">
              <!-- <input type="password"  autocomplete="off" name="beuPassword" [(ngModel)]="beuPassword" > -->
              <input
                autocomplete="off"
                [type]="passwordToggleHide?'password':'text'"
                name="beuPassword"
                id=""
                [(ngModel)]="beuPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="passwordToggleHide"
                (click)="passwordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!passwordToggleHide"
                (click)="passwordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="confirm_password">Confirm Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="conpasswordToggleHide?'password':'text'"
                name="beuConfirmPassword"
                id=""
                [(ngModel)]="beuConfirmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="conpasswordToggleHide"
                (click)="conpasswordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!conpasswordToggleHide"
                (click)="conpasswordToggleHide = true"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------ Retail DETAILS --------------------------------------------------- -->

      <div class="ces_pd_main_div" *ngIf="type == 'retail'">
        <div class="ces_pd_inp_main_div">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Name</label>
            <input type="text" [(ngModel)]="retailName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" [(ngModel)]="retailEmail" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" [(ngModel)]="retailNumber" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">Company Name</label>
            <input type="text" [(ngModel)]="retailCompName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">Address</label>
            <input type="text" [(ngModel)]="retailAddress" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">City</label>
            <input type="text" [(ngModel)]="retailCity" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="state">State</label>
            <ng-select
              class="multi-select"
              [items]="allState"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              (change)="onMaterialGroupChange($event)"
              [(ngModel)]="retailSelectedState"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="password">Create Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="passwordToggleHide?'password':'text'"
                name="retailPassword"
                id=""
                [(ngModel)]="retailPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="passwordToggleHide"
                (click)="passwordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!passwordToggleHide"
                (click)="passwordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="confirm_password">Confirm Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="conpasswordToggleHide?'password':'text'"
                name="retailConfirmPassword"
                id=""
                [(ngModel)]="retailConfirmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="conpasswordToggleHide"
                (click)="conpasswordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!conpasswordToggleHide"
                (click)="conpasswordToggleHide = true"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------ BM DETAILS --------------------------------------------------- -->

      <div class="ces_pd_main_div" *ngIf="type == 'BM'">
        <div class="ces_pd_inp_main_div">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Name</label>
            <input type="text" [(ngModel)]="bmName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" [(ngModel)]="bmEmail" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" [(ngModel)]="bmNumber" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">Company Name</label>
            <input type="text" [(ngModel)]="bmCompName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">City</label>
            <input type="text" [(ngModel)]="bmCity" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="state">State</label>
            <ng-select
              class="multi-select"
              [items]="allState"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              (change)="onMaterialGroupChange($event)"
              [(ngModel)]="bmSelectedState"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="password">Create Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="passwordToggleHide?'password':'text'"
                name="bmPassword"
                id=""
                [(ngModel)]="bmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="passwordToggleHide"
                (click)="passwordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!passwordToggleHide"
                (click)="passwordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="confirm_password">Confirm Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="conpasswordToggleHide?'password':'text'"
                name="bmConfirmPassword"
                id=""
                [(ngModel)]="bmConfirmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="conpasswordToggleHide"
                (click)="conpasswordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!conpasswordToggleHide"
                (click)="conpasswordToggleHide = true"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------ AM DETAILS --------------------------------------------------- -->

      <div class="ces_pd_main_div" *ngIf="type == 'AM'">
        <div class="ces_pd_inp_main_div">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Name</label>
            <input type="text" [(ngModel)]="amName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" [(ngModel)]="amEmail" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" [(ngModel)]="amNumber" />
          </div>
          <!-- <div class="ces_pd_inp_sub_div">
                        <label for="name">Company Name</label>
                        <input type="text">
                    </div> -->
          <div class="ces_pd_inp_sub_div">
            <label for="name">City</label>
            <input type="text" [(ngModel)]="amCity" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="state">State</label>
            <ng-select
              class="multi-select"
              [items]="allState"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              (change)="onMaterialGroupChange($event)"
              [(ngModel)]="amSelectedState"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="password">Create Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="passwordToggleHide?'password':'text'"
                name="amPassword"
                id=""
                [(ngModel)]="amPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="passwordToggleHide"
                (click)="passwordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!passwordToggleHide"
                (click)="passwordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="confirm_password">Confirm Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="conpasswordToggleHide?'password':'text'"
                name="amConfirmPassword"
                id=""
                [(ngModel)]="amConfirmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="conpasswordToggleHide"
                (click)="conpasswordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!conpasswordToggleHide"
                (click)="conpasswordToggleHide = true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="flex: 1">&nbsp;</div>
  <div class="ces_btn_main_div" style="margin-bottom: 2rem">
    <div class="" *ngIf="roleId "></div>
    <div class="ces_pd_btn_div">
      <div (click)="close()">CANCEL</div>
      <div (click)="addNewUser()">ADD</div>
    </div>
  </div>
</div>

<style>
  .add_user_header {
    display: flex;
    gap: 26px;
    color: #484a46;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.28px;
  }

  .add_user_header input[type="radio"] {
    cursor: pointer;
    accent-color: #0a9b45;
  }

  .add_user_header span {
    margin-left: 10px;
  }
</style>
