<div class="web_user_main_div">
  <div class="header_main_div">
    <div class="header">USER MANAGEMENT</div>
    <!-- <div class="adding_btn_main_div">
            <button (click)="addNewBM()"><img src="../../../assets/img/plus_icon.svg" alt=""><span>
                ADD  USERS</span></button>
        </div> -->
  </div>
  <div class="swiching_web_user_btn_main_div">
    <!-- <div class="u2_entriprise_user"
            (click)="handleSwicthWebUser('ces')"> ENTERPRISE USER 
        </div> -->
    <button
      class="swiching_web_user_btn"
      [ngClass]="
        selectedTable == 'enterprise' ? 'swiching_web_user_btn_active' : ''
      "
      (click)="handleSwicthWebUser('enterprise')"
    >
      ENTERPRISE USER
    </button>
    <!-- <button class="swiching_web_user_btn" [ngClass]="selectedTable=='retail' ? 'swiching_web_user_btn_active' : ''" (click)="handleSwicthWebUser('retail')">
            RETAIL USER
        </button> -->
  </div>

  <div class="web_user_content_main_div">
    <!-- ----------------------------------------ENTERPRISE USER TABLE ------------------------ ------------------------------ -->

    <div *ngIf="selectedTable == 'enterprise'">
      <div class="web_user_search_main_div">
        <div class="web_user_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilterCES($event)"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="dropdown">
          <button
            class="btn filter_btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../../../assets/img/../img/filter_icon.svg"
              alt=""
            />Filters
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              style="color: #5f6370"
              [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
              (click)="filterByStatus('All')"
            >
              All
            </li>
            <li
              class="dropdown-item"
              style="color: #12c058"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filterByStatus('active')"
            >
              Active Enterprise User
            </li>
            <li
              class="dropdown-item"
              style="color: #d43738"
              [ngClass]="selectedFilter.expired ? 'filterActiveCss' : ''"
              (click)="filterByStatus('inactive')"
            >
              In Active Enterprise User
            </li>
          </ul>
        </div>
        <div
          class="filterApplied"
          (click)="filterByStatus('All')"
          *ngIf="selectedFilterDropdown != 'All'"
        >
          {{ selectedFilterDropdown }}
          <img
            style="width: 15px; height: 15px; margin-left: 20px"
            src="../../../assets/img/xmark.svg"
          />
        </div>
      </div>

      <div class="web_user_table_main_div">
        <table
          mat-table
          [dataSource]="dataSourceCES"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <!-- ENTERPRISE USER Name  -->
          <ng-container matColumnDef="name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Enterprise User Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EUNameFilter ? EUNameFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EUNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EUApplyFilter('', 'name', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EUNameDropdown"
                      (click)="EUApplyFilter(status, 'name', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              style="color: #7893ec; cursor: pointer"
              (click)="handelModifyBM(element, 'enterprise')"
              mat-cell
              *matCellDef="let element"
            >
              {{ element.name }}
            </td>
          </ng-container>

          <!-- Company Name-->
          <ng-container matColumnDef="company_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EUCompanyFilter ? EUCompanyFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EUCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EUApplyFilter('', 'company', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EUCompanyDropdown"
                      (click)="EUApplyFilter(status, 'company', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.company_name }}
            </td>
          </ng-container>

          <!-- No. of Licenses -->
          <ng-container matColumnDef="licenses">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div style="display: flex; flex-direction: column; height: 7rem; justify-content: space-between; padding: 10px 0;">
                <p mat-sort-header>No. of Licenses</p>
                <!-- <div class="dropdown">
                  <button class="btn btnn filter_btn multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img *ngIf="!EuLicensesFilter" src="../../../assets/img/../img/Group.svg" alt=""/>
                    {{ EuLicensesFilter ? EuLicensesFilter : "All" }}
                    <img style="width: 20px" *ngIf="EuLicensesFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt=""
                      (click)="EuApplyFilter('', 'licenses', 1)"/>
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item" style="color: #5f6370" [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuLicensesDropdown" (click)="EuApplyFilter(status, 'licenses', 0)">
                      {{ status }}
                    </li>
                  </ul>
                </div> -->
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
              {{ element.licenses}}
            </td>
          </ng-container>

          <!-- City -->
          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EUCityFilter ? EUCityFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EUCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EUApplyFilter('', 'city', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EUCityDropdown"
                      (click)="EUApplyFilter(status, 'city', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.state }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th
              class="common_table_data"
              style="width: 6rem"
              mat-header-cell
              *matHeaderCellDef
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Date</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUDateFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EUDateFilter ? EUDateFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EUDateFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EUApplyFilter('', 'date', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EUDateDropdown"
                      (click)="EUApplyFilter(status, 'date', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.date }}
            </td>
          </ng-container>

          <!-- Status -->
          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EUStatusFilter ? EUStatusFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EUStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EUApplyFilter('', 'state', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? '' : ''"
                      *ngFor="let status of EUStatusDropdown"
                      (click)="EUApplyFilter(status, 'state', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="
                  element.status == 'Active'
                    ? 'colorGreen'
                    : element.status == 'Inactive'
                      ? 'colorRed'
                      : 'colorBlue'
                "
              >
                {{ element.status }}</span
              >
            </td>
          </ng-container>

          <!-- Reset Password -->
          <ng-container matColumnDef="reset_password">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Reset Password
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/reset_icon.svg"
                alt=""
                srcset=""
                (click)="resetPasswordBM(element.id, 'eu')"
                style="cursor: pointer"
              />
            </td>
          </ng-container>
          <!-- Modify -->
          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="handelDeleteBM(element.id, 'enterprise')"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img (click)="handelModifyBM(element,'enterprise')" style="cursor: pointer;" src="../../../assets/img/edit_icon.svg" alt=""> -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsCES"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsCES"></tr>
        </table>
      </div>
    </div>

    <!-- --------------------------------------Retail User-------------------------------------- ---------------- -->

    <div *ngIf="selectedTable == 'retail'">
      <div class="web_user_search_main_div">
        <div class="web_user_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilterRetail($event)"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="dropdown">
          <button
            class="btn filter_btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../../../assets/img/../img/filter_icon.svg"
              alt=""
            />Filters
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              style="color: #5f6370"
              [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
              (click)="filterByStatus('All')"
            >
              All
            </li>
            <li
              class="dropdown-item"
              style="color: #12c058"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filterByStatus('old_user')"
            >
              Old User
            </li>
            <li
              class="dropdown-item"
              style="color: #d43738"
              [ngClass]="selectedFilter.expired ? 'filterActiveCss' : ''"
              (click)="filterByStatus('new_user')"
            >
              New User
            </li>
            <!-- <li class="dropdown-item" style="color: #1968B1;"
                            [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                            (click)="filterByStatus('Requested')">
                            Requested</li> -->
          </ul>
        </div>
        <div
          class="filterApplied"
          (click)="filterByStatus('All')"
          *ngIf="selectedFilterDropdown != 'All'"
        >
          {{ selectedFilterDropdown }}
          <img
            style="width: 15px; height: 15px; margin-left: 20px"
            src="../../../assets/img/xmark.svg"
          />
        </div>
      </div>
      <div class="web_user_table_main_div">
        <table
          mat-table
          [dataSource]="dataSourceRetail"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <!-- RETAIK USER Name  -->
          <ng-container matColumnDef="name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Retail User Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RUNameFilter ? RUNameFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RUNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'state', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? '' : ''"
                      *ngFor="let status of RUNameDropdown"
                      (click)="RuApplyFilter(status, 'state', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>

          <!-- Company Name-->
          <ng-container matColumnDef="company_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RuCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RuCompanyFilter ? RuCompanyFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RuCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'company', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? '' : ''"
                      *ngFor="let status of RUCompanyDropdown"
                      (click)="RuApplyFilter(status, 'company', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.company_name }}
            </td>
          </ng-container>

          <!-- No. of Licenses -->
          <ng-container matColumnDef="licenses">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div style="display: flex; flex-direction: column; height: 7rem; justify-content: space-between; padding: 10px 0;">
                <p mat-sort-header>No. of Licenses</p>
                <!-- <div class="dropdown">
                  <button class="btn btnn filter_btn multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img *ngIf="!EuLicensesFilter" src="../../../assets/img/../img/Group.svg" alt=""/>
                    {{ EuLicensesFilter ? EuLicensesFilter : "All" }}
                    <img style="width: 20px" *ngIf="EuLicensesFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt=""
                      (click)="EuApplyFilter('', 'licenses', 1)"/>
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item" style="color: #5f6370" [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuLicensesDropdown" (click)="EuApplyFilter(status, 'licenses', 0)">
                      {{ status }}
                    </li>
                  </ul>
                </div> -->
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
              {{ element.licenses}}
            </td>
          </ng-container>

          <!-- City -->
          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>City</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RuCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RuCityFilter ? RuCityFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RuCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'city', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? '' : ''"
                      *ngFor="let status of RUCityDropdown"
                      (click)="RuApplyFilter(status, 'city', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.state }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th
              class="common_table_data"
              style="width: 6rem"
              mat-header-cell
              *matHeaderCellDef
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Date</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RuDateFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RuDateFilter ? RuDateFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RuDateFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'date', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? '' : ''"
                      *ngFor="let status of RUDateDropdown"
                      (click)="RuApplyFilter(status, 'date', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.date }}
            </td>
          </ng-container>

          <!-- Status -->
          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 6rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RuStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RuStatusFilter ? RuStatusFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RuStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'status', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? '' : ''"
                      *ngFor="let status of RUStatusDropdown"
                      (click)="RuApplyFilter(status, 'status', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="
                  element.status == 'Active'
                    ? 'colorGreen'
                    : element.status == 'Inactive'
                      ? 'colorRed'
                      : 'colorBlue'
                "
              >
                {{ element.status }}</span
              >
            </td>
          </ng-container>

          <!-- Reset Password -->
          <ng-container matColumnDef="reset_password">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Reset Password
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/reset_icon.svg"
                alt=""
                srcset=""
                (click)="resetPasswordBM(element.id, 'ru')"
                style="cursor: pointer"
              />
            </td>
          </ng-container>
          <!-- Modify -->
          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="handelDeleteBM(element.id, 'retail')"
                />
                <div style="border-right: 1px solid #c2c2c2; margin: 0 5px">
                  &nbsp;
                </div>
                <img
                  (click)="handelModifyBM(element, 'retail')"
                  style="cursor: pointer"
                  src="../../../assets/img/edit_icon.svg"
                  alt=""
                />
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsCES"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsCES"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
