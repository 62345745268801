<div class="blog-scroll-container">
    <button class="scroll-btn left-btn" (click)="scrollLeft()">&#x276E;</button>
  
    <div #scrollContainer class="blog-row" (mousedown)="startDragging($event)">
      <a
        *ngFor="let post of posts"
        [href]="post.link"
        target="_blank"
        class="blog-card-link"
        (click)="handleClick($event)"
      >
        <div class="blog-card">
          <div class="card-content">
            <h4 class="card-title" [innerHTML]="post.title.rendered"></h4>
          </div>
          <div class="image-container">
            <img
              [src]="post.img"
              [alt]="post.title.rendered"
              class="card-image"
              draggable="false"
              style="max-height: 48px; max-width: 55px; padding: 5px; padding-left: 2px; margin-inline: 3px; "
            />
          </div>
        </div>
      </a>
      <!-- <div *ngIf="loadingMore$ | async" class="loading-more">
        <div class="loading-dot"></div>
      </div> -->

    </div>
  
    <button class="scroll-btn right-btn" (click)="scrollRight()">&#x276F;</button>
    <button class="see-all-btn" (click)="seeAll()">See All</button>

  </div>

