<div class="license_main_div">
  <div class="header">LICENSES</div>
  <div class="swiching_web_user_btn_main_div">
    <button
      class="swiching_web_user_btn"
      [ngClass]="enter ?'swiching_web_user_btn_active':''"
      (click)="handleSwicthWebUser('beu')"
    >
      ENTERPRISE USER
    </button>
    <button
      class="swiching_web_user_btn"
      [ngClass]="retail?'swiching_web_user_btn_active':''"
      (click)="handleSwicthWebUser('retail')"
    >
      RETAIL USER
    </button>
    <button
      class="swiching_web_user_btn upload_doc"
      [ngClass]="uploadDoc?'swiching_web_user_btn_active':''"
      (click)="handleSwicthWebUser('docs')"
    >
      UPLOAD TEMPLATE DOCUMENT
    </button>
    <button
      class="swiching_web_user_btn upload_doc"
      [ngClass]="archive?'swiching_web_user_btn_active':''"
      (click)="handleSwicthWebUser('archive')"
    >
      ARCHIVED
    </button>
  </div>
  <div class="license_content_main_div" *ngIf="enter">
    <div>
      <div class="license_search_main_div">
        <div class="license_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilter($event)"
              autocomplete="off"
            />
          </div>
          <div class="dropdown">
            <button
              class="btn filter_btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="../../../assets/img/../img/filter_icon.svg"
                alt=""
              />Filters
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.all?'filterActiveCss':''"
                (click)="filterByStatus('All')"
              >
                All
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.active?'filterActiveCss':''"
                (click)="filterByRetailStatus('Active')"
              >
                Active
              </li>
              <li
                class="dropdown-item"
                style="color: #d43738"
                [ngClass]="selectedFilter.expired?'filterActiveCss':''"
                (click)="filterByRetailStatus('Expired')"
              >
                Expired
              </li>
              <li
                class="dropdown-item"
                style="color: #1968b1"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('Requested')"
              >
                Requested
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('30')"
              >
                Expiring in less than 30 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('60')"
              >
                Expiring in 60 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('90')"
              >
                Expiring in 90 days
              </li>
            </ul>
          </div>
          <div
            class="filterApplied"
            (click)="filterByRetailStatus('All')"
            *ngIf="selectedFilterDropdown!='All'"
          >
            {{selectedFilterDropdown}}
            <img
              style="width: 15px; height: 15px; margin-left: 20px"
              src="../../../assets/img/xmark.svg"
            />
          </div>
        </div>
      </div>
      <div class="license_table_main_div">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          style="width: 100%"
          matSort
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-sort-header mat-header-cell *matHeaderCellDef>
              SN.
            </th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{i+1}}
            </td>
          </ng-container>

          <ng-container matColumnDef="user_name">
            <th
              class="common_table_data"
              mat-header-cell
              *matHeaderCellDef
              style="min-width: 10rem"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>User Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUUserNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUUserNameFilter?EUUserNameFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="EUUserNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('','uname',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #6386fa"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUUserNameDropdown"
                      (click)="EuApplyFilter(status,'uname',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              (click)="getUserDetails(element.user_id,'eu')"
              style="color: #7893ec; cursor: pointer"
              mat-cell
              *matCellDef="let element"
            >
              {{element.user_name.user_name}}
            </td>
          </ng-container>

          <!-- License Number  -->

          <ng-container matColumnDef="registration_no">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>License Number</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EULicenseFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EULicenseFilter?EULicenseFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="EULicenseFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('','license',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EULicenseDropdown"
                      (click)="EuApplyFilter(status,'license',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="color: #7893ec; cursor: pointer"
              (click)="modifyAppUser(element.registration_no,'enterprise')"
            >
              {{element.registration_no}}
            </td>
          </ng-container>

          <!-- Company Name  -->

          <ng-container matColumnDef="business_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUCompanyFilter?EUCompanyFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="EUCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('','company',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUCompanyDropdown"
                      (click)="EuApplyFilter(status,'company',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.business_name}}
            </td>
          </ng-container>

          <!-- City -->

          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUCityFilter?EUCityFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="EUCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('','city',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUCityDropdown"
                      (click)="EuApplyFilter(status,'city',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.state}}
            </td>
          </ng-container>

          <!-- Status -->

          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EUStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{EUStatusFilter?EUStatusFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="EUStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('','status',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of EUStatusDropdown"
                      (click)="EuApplyFilter(status,'status',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Expired'?'colorRed':'colorBlue'"
              >
                {{element.status}}</span
              >
            </td>
          </ng-container>

          <!--  View License -->

          <ng-container matColumnDef="view_license">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              View License
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
              [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
              alt="View License"
              [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
              (click)="element.documents ? viewLicence(element.documents, element) : null"
            />
            </td>
          </ng-container>

          <!-- Modify -->

          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Archive
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/archive.svg"
                  alt=""
                  style="cursor: pointer; width: 30px"
                  (click)="makeArchive(element,'enterprise')"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                    <img src="../../../assets/img/edit_icon.svg" alt="" style="cursor: pointer;"
                                    (click)="modifyAppUser(element.registration_no,'enterprise')"> -->
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="serviceReq">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Service Request
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element; let i = index"
            >
              <img
                (click)="addRequest(element)"
                style="width: 40px"
                src="../../../assets//img//serviceReq.svg"
              />
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="license_content_main_div" *ngIf="retail">
    <div>
      <div class="license_search_main_div">
        <div class="license_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyRetailFilter($event)"
              autocomplete="off"
            />
          </div>
          <div class="dropdown">
            <button
              class="btn filter_btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="../../../assets/img/../img/filter_icon.svg"
                alt=""
              />Filters
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedRetailFilter.all?'filterActiveCss':''"
                (click)="filterByRetailStatus('All')"
              >
                All
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedRetailFilter.active?'filterActiveCss':''"
                (click)="filterByRetailStatus('Active')"
              >
                Active
              </li>
              <li
                class="dropdown-item"
                style="color: #d43738"
                [ngClass]="selectedRetailFilter.expired?'filterActiveCss':''"
                (click)="filterByRetailStatus('Expired')"
              >
                Expired
              </li>
              <li
                class="dropdown-item"
                style="color: #1968b1"
                [ngClass]="selectedRetailFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('Requested')"
              >
                Requested
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('30')"
              >
                Expiring in less than 30 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('60')"
              >
                Expiring in 60 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('90')"
              >
                Expiring in 90 days
              </li>
            </ul>
          </div>
          <div
            class="filterApplied"
            (click)="filterByRetailStatus('All')"
            *ngIf="selectedFilterDropdown!='All'"
          >
            {{selectedFilterDropdown}}
            <img
              style="width: 15px; height: 15px; margin-left: 20px"
              src="../../../assets/img/xmark.svg"
            />
          </div>
        </div>
      </div>
      <div class="license_table_main_div">
        <table
          mat-table
          [dataSource]="dataRetailSource"
          matSort
          class="mat-elevation-z8"
          style="width: 100%"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell mat-sort-header *matHeaderCellDef>
              SN.
            </th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{i+1}}
            </td>
          </ng-container>

          <ng-container matColumnDef="user_name">
            <th
              class="common_table_data"
              mat-header-cell
              *matHeaderCellDef
              style="min-width: 10rem"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>User Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUUserNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RUUserNameFilter?RUUserNameFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="RUUserNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('','uname',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RUUserNameDropdown"
                      (click)="RuApplyFilter(status,'uname',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              (click)="getUserDetails(element.user_id,'ru')"
              style="color: #7893ec; cursor: pointer"
              mat-cell
              *matCellDef="let element"
            >
              {{element.user_name.user_name}}
            </td>
          </ng-container>

          <!-- License Number  -->

          <ng-container matColumnDef="registration_no">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>License Number</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RULicenseFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RULicenseFilter?RULicenseFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="RULicenseFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('','license',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RULicenseDropdown"
                      (click)="RuApplyFilter(status,'license',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="color: #7893ec; cursor: pointer"
              (click)="modifyAppUser(element.registration_no,'retail')"
            >
              {{element.registration_no}}
            </td>
          </ng-container>

          <!-- Company Name  -->

          <ng-container matColumnDef="business_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Company</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RUCompanyFilter?RUCompanyFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="RUCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('','company',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RUCompanyDropdown"
                      (click)="RuApplyFilter(status,'company',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.business_name}}
            </td>
          </ng-container>

          <!-- City Name  -->

          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RUCityFilter?RUCityFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="RUCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('','city',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RUCityDropdown"
                      (click)="RuApplyFilter(status,'city',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.state}}
            </td>
          </ng-container>

          <!-- Status -->

          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{RUStatusFilter?RUStatusFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="RUStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('','status',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of RUStatusDropdown"
                      (click)="RuApplyFilter(status,'status',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Expired'?'colorRed':'colorBlue'"
              >
                {{element.status}}</span
              >
            </td>
          </ng-container>

          <!--  View License -->

          <ng-container matColumnDef="view_license">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              View License
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <!-- <img
                src="../../../assets/img/carbon_view.svg"
                alt=""
                style="cursor: pointer"
                (click)="viewLicence(element.documents,element)"
              /> -->
              <img
              [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
              alt="View License"
              [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
              (click)="element.documents ? viewLicence(element.documents, element) : null"
            />
            </td>
          </ng-container>

          <!-- Modify -->

          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Archive
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/archive.svg"
                  alt=""
                  style="cursor: pointer; width: 30px"
                  (click)="makeArchive(element,'retail')"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img src="../../../assets/img/edit_icon.svg" alt="" style="cursor: pointer;"
                                > -->
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="serviceReq">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Service Request
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element; let i = index"
            >
              <img
                (click)="addRequest(element)"
                style="width: 40px"
                src="../../../assets//img//serviceReq.svg"
              />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedRetailColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedRetailColumns;"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="license_content_main_div" *ngIf="archive">
    <div>
      <div class="license_search_main_div">
        <div class="license_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilter($event)"
              autocomplete="off"
            />
          </div>
          <div class="dropdown">
            <button
              class="btn filter_btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="../../../assets/img/../img/filter_icon.svg"
                alt=""
              />Filters
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedRetailFilter.all?'filterActiveCss':''"
                (click)="filterByRetailStatus('All')"
              >
                All
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedRetailFilter.active?'filterActiveCss':''"
                (click)="filterByRetailStatus('Active')"
              >
                Active
              </li>
              <li
                class="dropdown-item"
                style="color: #d43738"
                [ngClass]="selectedRetailFilter.expired?'filterActiveCss':''"
                (click)="filterByRetailStatus('Expired')"
              >
                Expired
              </li>
              <li
                class="dropdown-item"
                style="color: #1968b1"
                [ngClass]="selectedRetailFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('Requested')"
              >
                Request Sent
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('30')"
              >
                Expiring in less than 30 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('60')"
              >
                Expiring in 60 days
              </li>
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                (click)="filterByRetailStatus('90')"
              >
                Expiring in 90 days
              </li>
            </ul>
          </div>
          <div
            class="filterApplied"
            (click)="filterByRetailStatus('All')"
            *ngIf="selectedFilterDropdown!='All'"
          >
            {{selectedFilterDropdown}}
            <img
              style="width: 15px; height: 15px; margin-left: 20px"
              src="../../../assets/img/xmark.svg"
            />
          </div>
        </div>
      </div>
      <div class="license_table_main_div">
        <table
          mat-table
          [dataSource]="dataArchiveSource"
          matSort
          class="mat-elevation-z8"
          style="width: 100%"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell mat-sort-header *matHeaderCellDef>
              SN.
            </th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{i+1}}
            </td>
          </ng-container>
          <ng-container matColumnDef="licenseType">
            <th class="" mat-header-cell mat-sort-header *matHeaderCellDef>
              License Type
            </th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{element.user_type == 'enterprise_user' ? 'Enterprise' :
              'Retail'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="user_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>User Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!ArchiveUserNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ArchiveUserNameFilter?ArchiveUserNameFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="ArchiveUserNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="ArchiveApplyFilter('','uname',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of ArchiveUserNameDropdown"
                      (click)="ArchiveApplyFilter(status,'uname',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.user_name.user_name}}
            </td>
          </ng-container>

          <!-- License Number  -->

          <ng-container matColumnDef="registration_no">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>License Number</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!ArchiveLicenseFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ArchiveLicenseFilter?ArchiveLicenseFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="ArchiveLicenseFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="ArchiveApplyFilter('','license',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of ArchiveLicenseDropdown"
                      (click)="ArchiveApplyFilter(status,'license',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.registration_no}}
            </td>
          </ng-container>

          <!-- Company Name  -->

          <ng-container matColumnDef="business_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Company</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!ArchiveCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ArchiveCompanyFilter?ArchiveCompanyFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="ArchiveCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="ArchiveApplyFilter('','company',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of ArchiveCompanyDropdown"
                      (click)="ArchiveApplyFilter(status,'company',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.business_name}}
            </td>
          </ng-container>

          <!-- City Name  -->

          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!ArchiveCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ArchiveCityFilter?ArchiveCityFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="ArchiveCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="ArchiveApplyFilter('','city',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of ArchiveCityDropdown"
                      (click)="ArchiveApplyFilter(status,'city',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{element.state}}
            </td>
          </ng-container>

          <!-- Status -->

          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 5rem;
                  justify-content: space-between;
                  padding: 10px 0;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!ArchiveStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ArchiveStatusFilter?ArchiveStatusFilter:'All'}}
                    <img
                      style="width: 20px"
                      *ngIf="ArchiveStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="ArchiveApplyFilter('','status',1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all?'':''"
                      *ngFor="let status of ArchiveStatusDropdown"
                      (click)="ArchiveApplyFilter(status,'status',0)"
                    >
                      {{status}}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Expired'?'colorRed':'colorBlue'"
              >
                {{element.status}}</span
              >
            </td>
          </ng-container>

          <!--  View License -->

          <ng-container matColumnDef="view_license">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              View License
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
              [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
              alt="View License"
              [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
              (click)="element.documents ? viewLicence(element.documents, element) : null"
            />
            </td>
          </ng-container>

          <!-- Modify -->

          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/restore.svg"
                  alt=""
                  style="cursor: pointer; width: 40px"
                  (click)="makeRestore(element)"
                />
                <div
                  style="
                    border-right: 1px solid #c2c2c2;
                    margin: 0 5px;
                    margin-left: 0px;
                  "
                >
                  &nbsp;
                </div>
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="deleteLicense(element)"
                />
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="serviceReq">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Service Request
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element; let i = index"
            >
              <img
                (click)="addRequest(element)"
                style="width: 40px"
                src="../../../assets//img//serviceReq.svg"
              />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedArchiveColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedArchiveColumns;"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="license_content_main_div" *ngIf="uploadDoc">
    <div>
      <div class="license_search_main_div">
        <div
          class="license_search_sub_div"
          style="justify-content: space-between"
        >
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyDocFilter($event)"
              autocomplete="off"
            />
          </div>
          <div
            class="uploadDocBtn"
            style="margin-right: 20px"
            (click)="uploadDocBtn()"
          >
            <button>
              <img src="../../../assets/img/plus_icon.svg" alt="" /><span
                >UPLOAD TEMPLATE DOCUMENT</span
              >
            </button>
          </div>
        </div>
      </div>
      <div class="license_table_main_div">
        <table
          mat-table
          [dataSource]="dataDocsSource"
          matSort
          class="mat-elevation-z8"
          style="width: 100%"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{i+1}}
            </td>
          </ng-container>

          <!-- License Number  -->

          <ng-container matColumnDef="doc_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <p>Document Name</p>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="color: #7893ec; cursor: pointer"
              (click)="editSampleDocument(element.id)"
            >
              {{element.name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="desc">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <p>Description</p>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
            >
            {{element.description?.length > 40 ? (element.description | slice: 0:40) + '...' : element.description}}
          </td>
          </ng-container>

          <ng-container matColumnDef="view">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              View Document
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
<img
              [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
              alt="View License"
              [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
              (click)="element.documents ? viewLicence(element.documents, element) : null"
            />
            </td>
          </ng-container>

          <!-- Modify -->

          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="deleteDocument(element.id)"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img src="../../../assets/img/edit_icon.svg" alt="" style="cursor: pointer;" (click)="editSampleDocument(element.id)" > -->
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedDocsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedDocsColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
